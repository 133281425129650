import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./blog.scss"
import Signup from "../components/signup"

const BlogPage = ({ data }) => {
  const blogPosts = data.allContentfulPost.edges
  return (
    <Layout>
      <SEO title={"Software Development Blog"} />
      <div id={"blogPage"}>
        <div className="section section-blogCollection section-reverse">
          <div className="container">
            <div className="section-body">
              <div className="grid slideBottomGroup">
                {blogPosts.map(({ node: post }) => (
                  <div
                    key={post.slug}
                    className="controlled-by-tag col col-4xl col-6m col-12s"
                    tags={post.tags.toString()}
                  >
                    <a
                      href={`/blog/${post.slug}`}
                      className="card card-postItem"
                      target="_blank"
                    >
                      <div className="card-header">
                        <figure className="card-media">
                          <img
                            className="icon lazy"
                            src={post.bannerPreview.file.url}
                            alt={post.bannerPreview.title}
                          />
                        </figure>
                      </div>
                      <div className="card-body">
                        <div className="button-group">
                          {post.tags.map(tag => (
                            <div key={tag} className="button-wrap">
                              <div className="button b-medium tag-selectable tag-primary small">
                                {tag}
                              </div>
                            </div>
                          ))}
                        </div>

                        <h2 className="card-title b-bold">{post.title}</h2>

                        <p className="card-description">{post.bannerText}</p>
                      </div>
                    </a>
                  </div>
                ))}

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s uk-scrollspy-inview uk-animation-slide-bottom"
                  tags="video analysis, computer vision"
                  
                >
                  <a
                    href="/live-stream-video-analysis"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          src="../../blog/current/live-stream-video-analysis.jpg"
                          data-was-processed="true"
                          className="icon lazy loading"
                          alt="not found"
                          srcSet="../../blog/current/live-stream-video-analysis@2x.jpg 2x,
                                ../../blog/current/live-stream-video-analysis@3x.jpg 3x"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Video analysis
                          </div>
                        </div>
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Computer vision
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        Live Stream Video Analysis with Computer Vision: Use in
                        Business
                      </h2>

                      <p className="card-description">
                        Want to learn how to use AI for live stream analysis?
                        Read this article to find out how beneficial this
                        technology can be for your company’s productivity!
                      </p>
                      <div className="statistics-info"></div>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s uk-scrollspy-inview uk-animation-slide-bottom"
                  tags="app, photo"
                  
                >
                  <a
                    href="/guide-face-editing-app"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          src="../../blog/current/guide-face-editing-app.jpg"
                          data-was-processed="true"
                          className="icon lazy loading"
                          alt="not found"
                          srcSet="../../blog/current/guide-face-editing-app@2x.jpg 2x,
                                ../../blog/current/guide-face-editing-app@3x.jpg 3x"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            App
                          </div>
                        </div>
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Photo
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        A Complete Guide to Creating a Face Editing App — Best
                        Practices, Tips, and More
                      </h2>

                      <p className="card-description">
                        Do you want to develop a worthy FaceApp alternative? But
                        how much does it cost to develop an AI photo editor?
                        Learn the best practices, tips, and the cost in our
                        complete guide. Make your app go viral.
                      </p>
                      <div className="statistics-info"></div>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s uk-scrollspy-inview uk-animation-slide-bottom"
                  tags="crm, development"
                  
                >
                  <a
                    href="/custom-crm-development"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          src="../../blog/current/custom-crm-development.jpg"
                          data-was-processed="true"
                          className="icon lazy loading"
                          alt="not found"
                          srcSet="../../blog/current/custom-crm-development@2x.jpg 2x,
                                ../../blog/current/custom-crm-development@3x.jpg 3x"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            CRM
                          </div>
                        </div>
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Development
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        Custom CRM Development: Cost and Benefits
                      </h2>

                      <p className="card-description">
                        In the past few years, custom CRM systems have gained
                        large popularity. Many companies are using them
                        successfully to increase both productivity and profit.
                        However, most organizations are still only eyeing…
                      </p>
                      <div className="statistics-info"></div>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s uk-scrollspy-inview uk-animation-slide-bottom"
                  tags="webrtc, websockets"
                  
                >
                  <a
                    href="/webrtc-vs-websockets"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          src="../../blog/current/webrtc-websockets.jpg"
                          data-was-processed="true"
                          className="icon lazy loading"
                          alt="not found"
                          srcSet="../../blog/current/webrtc-websockets@2x.jpg 2x,
                                ../../blog/current/webrtc-websockets@3x.jpg 3x"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            WebRTC
                          </div>
                        </div>
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Websockets
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        WebRTC vs Websockets
                      </h2>

                      <p className="card-description">
                        Real-time peer-to-peer signaling and media exchange is
                        an important capability for most interactive web
                        applications. Use cases of direct exchange technologies
                        range from simple text chats to interactive solutions…
                      </p>
                      <div className="statistics-info"></div>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s uk-scrollspy-inview uk-animation-slide-bottom"
                  tags="streaming"
                  
                >
                  <a
                    href="/how-start-streaming-service"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          src="../../blog/current/how-start-streaming-service.jpg"
                          data-was-processed="true"
                          className="icon lazy loading"
                          alt="not found"
                          srcSet="../../blog/current/how-start-streaming-service@2x.jpg 2x,
                                ../../blog/current/how-start-streaming-service@3x.jpg 3x"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Streaming
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        How to Start a Streaming Service Easy and Fast
                      </h2>

                      <p className="card-description">
                        How to start an online video streaming business? Our
                        experts believe streaming is the media technology of the
                        future. Popular streaming platforms have already become
                        irreplaceable, and their profits are measured…
                      </p>
                      <div className="statistics-info"></div>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s uk-scrollspy-inview uk-animation-slide-bottom"
                  tags="app"
                  
                >
                  <a
                    href="/how-make-app-like-onlyfans"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          src="../../blog/current/how-make-app-like-onlyfans.jpg"
                          data-was-processed="true"
                          className="icon lazy loading"
                          alt="not found"
                          srcSet="../../blog/current/how-make-app-like-onlyfans@2x.jpg 2x,
                                ../../blog/current/how-make-app-like-onlyfans@3x.jpg 3x"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            App
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        How to Make an App Like OnlyFans
                      </h2>

                      <p className="card-description">
                        Do you want to create an OnlyFans app? Are you looking
                        for expert advice and professional consulting? Find out
                        more in this article.
                      </p>
                      <div className="statistics-info"></div>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s uk-scrollspy-inview uk-animation-slide-bottom"
                  tags="marketplace"
                  
                >
                  <a
                    href="/service-marketplace-development"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          src="../../blog/current/service-marketplace-development.jpg"
                          data-was-processed="true"
                          className="icon lazy loading"
                          alt="not found"
                          srcSet="../../blog/current/service-marketplace-development@2x.jpg 2x,
                                ../../blog/current/service-marketplace-development@3x.jpg 3x"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Marketplace
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        Service Marketplace Development: Full Guide
                      </h2>

                      <p className="card-description">
                        An online service marketplace is a place where a buyer
                        can compare and purchase services from several sellers
                        at once. The marketplace acts as an intermediary between
                        the consumer and those who offer their …
                      </p>
                      <div className="statistics-info">
                        {/*<div>*/}
                        {/*<img*/}
                        {/*src="../../../images/blogposts/eye-light.svg"*/}
                        {/*className="icon"*/}
                        {/*alt="view eye icon"*/}
                        {/*/>*/}
                        {/*<span>31</span>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*<img*/}
                        {/*src="../../../images/blogposts/heart.svg"*/}
                        {/*className="icon like not-liked"*/}
                        {/*alt="like heart icon"*/}
                        {/*/>*/}
                        {/*<img*/}
                        {/*src="../../../images/blogposts/heart-filled.svg"*/}
                        {/*className="icon like liked"*/}
                        {/*alt="like heart icon active"*/}
                        {/*/>*/}
                        {/*<span>12</span>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s uk-scrollspy-inview uk-animation-slide-bottom"
                  tags="app"
                  
                >
                  <a
                    href="/pwa-vs-native-app"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          src="../../blog/current/pwa-vs-native-app.jpg"
                          data-was-processed="true"
                          className="icon lazy loading"
                          alt="not found"
                          srcSet="../../blog/current/pwa-vs-native-app@2x.jpg 2x,
                                ../../blog/current/pwa-vs-native-app@3x.jpg 3x"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            App
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        PWA vs Native App: The Ultimate Choice
                      </h2>

                      <p className="card-description">
                        When planning to have an app for your business, you
                        should make a crucial choice of PWA vs native. Each type
                        has its own benefits, so the task is not an easy one.
                      </p>
                      <div className="statistics-info"></div>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s"
                  tags="Top development"
                >
                  <a
                    href="/clutch"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          className="icon lazy"
                          src={"../../blog/current/clutch.svg"}
                          alt="not found"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Top development
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        Requestum Is Named a Top Development Partner in Ukraine
                        for 2020 by Clutch!
                      </h2>

                      <p className="card-description">
                        In recognition of our success and industry value, we’ve
                        been named among the best development partners in
                        Ukraine by Clutch!
                      </p>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s"
                  tags="award"
                >
                  <a
                    href="/upwork"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          src="../../blog/current/upwork.jpg"
                          srcSet="../../blog/current/upwork@2x.jpg 2x,
                             ../../blog/current/upwork@3x.jpg 3x"
                          className="icon lazy"
                          alt="not found"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            award
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        Requestum Awarded the Best Upwork Agency 2019
                      </h2>

                      <p className="card-description">
                        Every year Upwork chooses the best agencies and
                        professionals under the program Upwork Ukraine Awards.
                        We are extremely pleased to receive the award of the The
                        Best Upwork Agency 2019 in Kharkiv…
                      </p>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s"
                  tags="iot, blockchain"
                >
                  <a
                    href="/blog/iot-and-blockchain"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          className="icon lazy"
                          src="../../blog/current/iot.svg"
                          alt="not found"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            IOT
                          </div>
                        </div>
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Blockchain
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        IoT and Blockchain - Automation &amp; Reliability “Out
                        of the Box”
                      </h2>

                      <p className="card-description">
                        If you have an idea to implement using the IoT and
                        blockchain, we will be happy to offer you what we got.
                        Our in-house developers have extensive experience of
                        working on IoT-, blockchain-, and IoT-blockchain-based
                        solutions.
                      </p>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s"
                  tags="ux/ui, web"
                >
                  <a
                    href="/estate-property-management-system"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          className="icon lazy"
                          src="../../blog/current/real-estate.svg"
                          alt="not found"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            UX/UI
                          </div>
                        </div>
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Web
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        HOW TO CREATE A REAL ESTATE PROPERTY MANAGEMENT SYSTEM
                      </h2>

                      <p className="card-description">
                        Real Estate software means to optimize workflow and
                        business efficiency. Read our guide on building a
                        property management system and the development cost
                        estimation.
                      </p>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s"
                  tags="ar, medicine"
                >
                  <a
                    href="/augmented-reality-healthcare"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          className="icon lazy"
                          src="../../blog/current/healthcare.svg"
                          alt="augmented reality healthcare"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            AR
                          </div>
                        </div>
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Medicine
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        MAKING HELP REAL: AUGMENTED REALITY IN HEALTHCARE
                      </h2>

                      <p className="card-description">
                        Augmented reality has stopped being considered
                        exclusively as entertainment. It’s application in
                        healthcare introduces more efficient ways of treating
                        patients.
                      </p>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s"
                  tags="app, rideshare"
                >
                  <a
                    href="/ride-sharing-app"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          className="icon lazy"
                          src="../../blog/current/rideshare.svg"
                          alt="rideshare app"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            App
                          </div>
                        </div>
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Rideshare
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        RIDING INTO THE SUNSET: HOW TO CREATE A RIDE SHARING APP
                      </h2>

                      <p className="card-description">
                        Not sure how to start a rideshare company and make it a
                        thriving business? Developing a unique and user-friendly
                        app is a necessary prerequisite to success.
                      </p>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s"
                  tags="e-learning"
                >
                  <a
                    href="/e-learning-platform"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          className="icon lazy"
                          src="../../blog/current/e-learning.svg"
                          alt="e-learning platform"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            E-learning
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        How to Make an e-Learning Platform
                      </h2>

                      <p className="card-description">
                        E-learning software development is of the highest
                        relevance these days. Learn how much the services of
                        related devs cost and what type of functionality to
                        settle.
                      </p>
                    </div>
                  </a>
                </div>

                <div
                  className="controlled-by-tag col col-4xl col-6m col-12s"
                  tags="cv, sports"
                >
                  <a
                    href="/computer-vision-in-sports"
                    className="card card-postItem"
                    target="_blank"
                  >
                    <div className="card-header">
                      <figure className="card-media">
                        <img
                          className="icon lazy"
                          src="../../blog/current/cv.svg"
                          alt="computer vision in sports"
                        />
                      </figure>
                    </div>
                    <div className="card-body">
                      <div className="button-group">
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            CV
                          </div>
                        </div>
                        <div className="button-wrap">
                          <div className="button b-medium tag-selectable tag-primary small">
                            Sports
                          </div>
                        </div>
                      </div>

                      <h2 className="card-title b-bold">
                        COMPUTER VISION USE CASES IN SPORTS INDUSTRY
                      </h2>

                      <p className="card-description">
                        The use of advanced CV or computer vision applications
                        in sports ultimately allows for a highly efficient,
                        fast, and precise analysis of actions, conditions, and
                        environments in all possible sports events.
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Signup/>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query AllBlogPostsPageQuery {
    allContentfulPost(limit: 200) {
      edges {
        node {
          id
          slug
          title
          bannerText
          bannerBgColor
          bannerPreview {
            title
            file {
              url
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
          category
          tags
          createdAt
        }
      }
    }
  }
`
