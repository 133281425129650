import PropTypes from "prop-types"
import React from "react"
import "./signup.scss"

const Signup = () => (
  <section
    className="section section-signup section-minContent scrollPage header-change"
    h-style="--bg-image: radial-gradient(circle at 27% -70%,#636265,#131316 65%);"
    noise="true"
  >
    <div className="container">
      <div className="section-layout">
        <div className="section-main slideBottomGroup">
          <header className="section-header">
            <h2 className="section-title b-bold">Sign up for updates!</h2>
          </header>
          <div className="section-body slideBottomGroup">
            <div className="section-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
            <div className="subscribe-form">
              <div className="subscribe-input-wrap">
                <input className="subscribe-input" placeholder="Email Address"/>
              </div>
              <div>
                <button className="button button-signup">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
        <div className="section-aside">
          <figure className="aside-picture slideBottom">
            <img
              className="img lazy"
              data-src="../../blog/current/signup.svg"
              src="../../blog/current/signup.svg"
              alt="signup image"
            />
          </figure>
        </div>
      </div>
    </div>
  </section>
)

Signup.propTypes = {
  siteTitle: PropTypes.string,
}

Signup.defaultProps = {
  siteTitle: ``,
}

export default Signup
